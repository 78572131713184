import statusOkData from './statusOk';

const defaultOptions = {
  loop: false,
  autoplay: true,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

const getLottieOptions = lottie => {
  let animationData = {};
  switch (lottie) {
    case 'statusOk':
      animationData = statusOkData;
      break;
    default:
      break;
  }
  return { ...defaultOptions, animationData };
};

export default getLottieOptions;
